import getWeb3 from './web3';
import abi from "../abi.json";

const contractAddress = "0x4934258fc50cCdd124b5bc149c1E32ae2c1aCD9a";

const init = async (setWeb3, setAccounts, setLotteryContract, setLotteryData, openPopup) => {
  try {
    const web3Instance = await getWeb3();
    setWeb3(web3Instance);
    const accounts = await web3Instance.eth.getAccounts();
    setAccounts(accounts);
    const lotteryInstance = new web3Instance.eth.Contract(abi, contractAddress);
    setLotteryContract(lotteryInstance);

    const fetchCurrentLottery = async () => {
      if (lotteryInstance) {
        try {
          const data = await lotteryInstance.methods.getCurrentLottery().call();
          setLotteryData({
            numberOfTickets: data.numberOfTickets.toString(),
            currentNumberOfTickets: data.currentNumberOfTickets.toString(),
            ticketPriceUnformated: data.ticketPrice,
            ticketPrice: web3Instance.utils.fromWei(data.ticketPrice.toString(), 'ether'),
            feePercent: data.feePercent.toString(),
            ended: data.ended,
            participants: data.participants,
            winner: `${data.winner.addr} has won ${web3Instance.utils.fromWei(data.winner.amount.toString(), 'ether')} MATIC`
          });
          console.log({data})
        } catch (error) {
          openPopup('Error al Obtener Datos', 'No se pudieron obtener los datos de la lotería actual. Por favor, intenta recargar la página.');
          console.error('Failed to fetch current lottery data:', error);
        }
      }
    };

    fetchCurrentLottery();
  } catch (error) {
    openPopup('MetaMask No Instalado', 'No se detectó MetaMask en tu navegador. Por favor, instala MetaMask para continuar.');
  }
};

export default init;
