import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { Box, Container, Stack } from '@mui/material';

const PieChart = ({ lotteryData, chartConfig }) => {
  const svgRef = useRef();
  const [data, setData] = useState([]);

  useEffect(() => {
    const segments = lotteryData ? lotteryData.numberOfTickets : 2;
    const purchasedSegments = lotteryData ? lotteryData.currentNumberOfTickets : 0;
    const availableSegments = segments - purchasedSegments;

    const dataArray = [];
    for (let i = 0; i < purchasedSegments; i++) {
      dataArray.push({ type: 'Purchased', value: 1, color: chartConfig.purchasedColor, available: false });
    }
    for (let i = 0; i < availableSegments; i++) {
      dataArray.push({ type: 'Available', value: 1, color: chartConfig.availableColor, available: true });
    }

    setData(dataArray);
  }, [lotteryData, chartConfig]);

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const width = chartConfig.width;
    const height = chartConfig.height;
    const radius = Math.min(width, height) / 2;
    const innerRadius = radius / 3; // Adjusted inner radius

    svg.selectAll('*').remove();

    const g = svg.append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const pie = d3.pie()
      .sort(null)
      .value(d => d.value);

    const arcPath = d3.arc()
      .outerRadius(radius - chartConfig.segmentPadding)
      .innerRadius(innerRadius);

    const expandedArcPath = d3.arc()
      .outerRadius(radius)
      .innerRadius(innerRadius + chartConfig.expandOffset)
      .padAngle(0.1); // Adds space between segments

    const arc = g.selectAll('.arc')
      .data(pie(data))
      .enter().append('g')
      .attr('class', 'arc')
      .on('mouseover', function (event, d) {
        if (d.data.available) {
          d3.select(this).select('path')
            .transition()
            .duration(200)
            .attr('d', expandedArcPath);
          d3.select(this).select('text')
            .transition()
            .duration(200)
            .attr('transform', d => `translate(${expandedArcPath.centroid(d)})`);
          document.body.style.cursor = 'pointer'; // Change cursor to pointer globally
        }
      })
      .on('mouseout', function (event, d) {
        if (d.data.available) {
          d3.select(this).select('path')
            .transition()
            .duration(200)
            .attr('d', arcPath);
          d3.select(this).select('text')
            .transition()
            .duration(200)
            .attr('transform', d => `translate(${arcPath.centroid(d)})`);
          document.body.style.cursor = 'default'; // Change cursor to default globally
        }
      });

    arc.append('path')
      .attr('d', arcPath)
      .attr('fill', d => d.data.color)
      .attr('stroke', chartConfig.borderColor)
      .attr('stroke-width', chartConfig.borderWidth);

    arc.append('text')
      .attr('transform', d => `translate(${arcPath.centroid(d)})`)
      .attr('dy', '0.35em')
      .style('fill', d => d.data.type === 'Available' ? 'black' : chartConfig.labelColor) // Set text color based on type
      .style('font-size', chartConfig.labelFontSize)
      .style('text-anchor', 'middle')
      .text(d => d.data.type);

    // Dibujar círculo central
    g.append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', innerRadius)
      .attr('fill', chartConfig.centerCircleColor);
  }, [data, chartConfig]);

  return (
    <svg ref={svgRef} width={chartConfig.width} height={chartConfig.height}></svg>
  );
};

const Hero = ({ handlePopupOpen, lotteryData }) => {
  const chartConfig = {
    width: 400,
    height: 400,
    segmentPadding: 10,
    labelPadding: 80,
    expandOffset: 10,
    purchasedColor: '#2d2d2d',
    availableColor: '#cfe75d',
    borderColor: '#222222',
    borderWidth: '2px',
    labelColor: '#fff',
    labelFontSize: '12px',
    centerCircleColor: '#cfe75d',
  };

  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        backgroundColor: '#121212!important',
        display:"flex",
        justifyContent:"center",
      })}
    >
      <PieChart lotteryData={lotteryData} chartConfig={chartConfig} />

    </Box>
  );
};

export default Hero;
