import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import startLottery from '../contracts/startLottery';

export default function StartLottery({ web3, accounts, lotteryContract, openPopup }) {
  const [numberOfTickets, setNumberOfTickets] = useState(0);
  const [ticketPrice, setTicketPrice] = useState(0);

  const handleNumberOfTicketsChange = (e) => {
    setNumberOfTickets(e.target.value);
  };

  const handleTicketPriceChange = (e) => {
    setTicketPrice(e.target.value);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
      <Card sx={{ minWidth: "350px" }}>
        <CardContent>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            Iniciar Lotería
          </Typography>
          <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
            <label htmlFor="number-of-tickets">Número de Tickets:</label>
            <input type="number" id="number-of-tickets" value={numberOfTickets} onChange={handleNumberOfTicketsChange} min="1" />
          </Typography>
          <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
            <label htmlFor="ticket-price">Precio del Ticket (en MATIC):</label>
            <input type="number" id="ticket-price" value={ticketPrice} onChange={handleTicketPriceChange} min="0.001" step="0.001" />
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => startLottery(web3, lotteryContract, accounts, numberOfTickets, ticketPrice, openPopup)}
            sx={{ color: "#342134", cursor: "pointer", marginRight: 1.5 }}
          >
            Iniciar Lotería
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
