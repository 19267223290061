import React, { useState, useEffect } from 'react';
import {
  CssBaseline, Container, ThemeProvider, createTheme, Box
} from '@mui/material';
import AppAppBar from './components/AppAppBar';
import Hero from './components/Hero';
import getTheme from './theme';
import init from './utils/init';
import BuyTicket from './components/BuyTicket';
import StartLottery from './components/StartLottery';
import AddOwner from './components/AddOwner';
import PickWinner from './components/PickWinner';
import AppModal from './components/AppModal';
import getOwners from './contracts/getOwners';
import LotteryHistory from './components/LotteryHistory'

const connectWalletHandler = async (web3, setAccounts, openPopup) => {
  try {
    const accounts = await web3.eth.requestAccounts();
    setAccounts(accounts);
  } catch (error) {
    openPopup('Error de Conexión', 'No se pudo conectar a la wallet. Por favor, asegúrate de que MetaMask esté instalado y que hayas aprobado la conexión.')
    console.log('Failed to connect wallet: ' + error.message);
  }
};

export default function App() {
  const [web3, setWeb3] = useState(null);
  const [accounts, setAccounts] = useState([]);
  const [lotteryContract, setLotteryContract] = useState(null);
  const [lotteryData, setLotteryData] = useState(null);
  const [open, setOpen] = useState(false);
  const [titleModal, setTitleModal] = useState("");
  const [descriptionModal, setDescriptionModal] = useState("");
  const [isOwner, setIsOwners] = useState(null);

  const theme = createTheme(getTheme("light"));


  

  const openPopup = (title, description) => {
    setTitleModal(title);
    setDescriptionModal(description);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  useEffect(() => {
    init(setWeb3, setAccounts, setLotteryContract, setLotteryData, openPopup);

	const fetchOwners = async () => {
		const ownersList = await getOwners(lotteryContract, openPopup);
		const _isOwner = accounts.some(e => ownersList.includes(e));
		setIsOwners(_isOwner)

	};

	if (lotteryContract) {
		fetchOwners();
	}

	
  }, [lotteryContract,accounts]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar connectWallet={() => connectWalletHandler(web3, setAccounts, openPopup)} accounts={accounts} />
      <Container sx={{ justifyItems: "center", marginBottom: 3, marginTop: "100px",display:"flex",flexDirection:"row" }}>
        <Hero handlePopupOpen={openPopup} lotteryData={lotteryData} />
		<Box display={"flex"} flexDirection={"column"}>
			<BuyTicket web3={web3} accounts={accounts} lotteryContract={lotteryContract} lotteryData={lotteryData} openPopup={openPopup} />
			<LotteryHistory lotteryContract={lotteryContract} openPopup={openPopup} />
			{isOwner&&<StartLottery web3={web3} accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
			{isOwner&&<AddOwner accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
			{isOwner&&<PickWinner accounts={accounts} lotteryContract={lotteryContract} openPopup={openPopup} />}
		</Box>

        
      </Container>
      <AppModal open={open} handleClose={handleClose} title={titleModal} description={descriptionModal} />
    </ThemeProvider>
  );
}
