import React from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import pickWinner from '../contracts/pickWinner';

export default function PickWinner({ accounts, lotteryContract, openPopup }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
      <Card sx={{ minWidth: "350px",width:"100%" }}>
        <CardContent>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            Seleccionar Ganador
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => pickWinner(lotteryContract, accounts, openPopup)}
            sx={{ color: "#342134", cursor: "pointer", marginRight: 1.5 }}
          >
            Seleccionar Ganador
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
