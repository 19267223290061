import handleTransactionError from '../utils/handleTransactionError';
/* global BigInt */

const buyTicket = async (web3, lotteryContract, accounts, ticketCount, lotteryData, openPopup) => {
  try {
    if (!window.ethereum) throw new Error("MetaMask not found");

    console.log('Initiating ticket purchase...');
    console.log('Current lottery data:', lotteryData);

    const ticketPriceWei = BigInt(lotteryData.ticketPriceUnformated); // Precio en Wei
    console.log('Ticket price (unformatted):', lotteryData.ticketPriceUnformated);
    console.log('Ticket price (number):', ticketPriceWei.toString());
    console.log('Ticket count:', ticketCount);

    const totalValueWei = ticketPriceWei * BigInt(ticketCount);
    console.log('Total value for tickets (Wei):', totalValueWei.toString());

    const balanceWei = await web3.eth.getBalance(accounts[0]);
    console.log('Account balance (Wei):', balanceWei);
    console.log('Account balance (Ether):', web3.utils.fromWei(balanceWei, 'ether'));

    // Enviar transacción
    await lotteryContract.methods.buyTicket(BigInt(ticketCount)).send({
      from: accounts[0],
      value: totalValueWei,
      //gas: 30000 // Establecer gas fijo a 30000
    })
      .on('transactionHash', (hash) => {
        console.log('Transaction hash:', hash);
      })
      .on('receipt', (receipt) => {
        console.log('Transaction receipt:', receipt);
        openPopup('Success', 'Tickets bought successfully');
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        console.log('Transaction confirmation number:', confirmationNumber);
      })
      .on('error', (error) => {
        console.error('Transaction error:', error);
        handleTransactionError(error, openPopup);
      });
  } catch (error) {
    console.error('Error buying tickets:', error);
    handleTransactionError(error, openPopup);
  }
};

export default buyTicket;
