import handleTransactionError from '../utils/handleTransactionError';

const pickWinner = async (lotteryContract, accounts, openPopup) => {
  try {
    await lotteryContract.methods.pickWinner().send({
      from: accounts[0],
      //gas: 30000 // Establecer gas fijo a 30000
    })
      .on('transactionHash', (hash) => {
        console.log('Transaction hash:', hash);
      })
      .on('receipt', (receipt) => {
        console.log('Receipt:', receipt);
        openPopup('Éxito', 'El ganador ha sido seleccionado exitosamente.');
      })
      .on('confirmation', (confirmationNumber, receipt) => {
        console.log('Confirmation number:', confirmationNumber);
      })
      .on('error', (error) => {
        console.error('Transaction error:', error);
        handleTransactionError(error, openPopup);
      });
  } catch (error) {
    console.error('Error picking winner:', error);
    handleTransactionError(error, openPopup);
  }
};

export default pickWinner;
