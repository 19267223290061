import * as React from 'react';

import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import MenuIcon from '@mui/icons-material/Menu';
import AddIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import WalletIcon from '@mui/icons-material/WalletOutlined';




function AppAppBar({connectWallet,accounts}) {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: 'transparent',
          backgroundImage: 'none',
          padding:0,
          margin:0
        }}
      >
        <Container sx={{
          padding:"0!important",
          margin:0,
          maxWidth:"100%!important"
        }}>
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexShrink: 0,
              bgcolor:'rgba(18, 18, 18, 0.97)',
              backdropFilter: 'blur(24px)',
              maxHeight: 50,
              padding:5,
              margin:0,
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: 'flex',
                alignItems: 'center',
                px: 0,
              }}
            >
              <p >Rat Lottery</p>
            </Box>
            <Box
              sx={{
                display: { xs: 'none', md: 'flex' },
                gap: 0.5,
                alignItems: 'center',
              }}
            >
                {accounts.length === 0 ?
                <Box>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    onClick={connectWallet}
                    sx={{ width: '100%' }}
                  >
                    <p style={{marginRight:3}}>Contectá tu wallet</p>
                    <WalletIcon />
                  </Button>
                </Box>
                :
                <Box>
                    <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    onClick={connectWallet}
                    sx={{marginRight:1}}
                  >
                    <AddIcon />
                  </Button>
                  <Button
                    color="primary"
                    variant="contained"
                    component="a"
                    onClick={connectWallet}
                  >
                    <p className="wallet">{accounts[0]}</p>
                  </Button>
                </Box>

                }
            </Box>
            <Box sx={{ display: { sm: '', md: 'none' } }}>
              <Button
                variant="text"
                color="primary"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ minWidth: '30px', p: '4px' }}
              >
                <MenuIcon />
              </Button>
              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: '60dvw',
                    p: 2,
                    backgroundColor: 'background.paper',
                    flexGrow: 1,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'end',
                      flexGrow: 1,
                    }}
                  >
                  </Box>
                    {accounts.length === 0 ?
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      onClick={connectWallet}
                      sx={{ width: '100%' }}
                    >
                      CONECTA TU WALLET
                    </Button>
                    :
                    <Button
                      color="primary"
                      variant="outlined"
                      component="a"
                      onClick={connectWallet}
                      sx={{ width: '100%' }}
                    >
                      {accounts[0]}
                    </Button>
                    }
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default AppAppBar;
