import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import TicketIcon from '@mui/icons-material/ConfirmationNumberOutlined';
import buyTicket from '../contracts/buyTicket';

export default function BuyTicket({ web3, accounts, lotteryContract, lotteryData, openPopup }) {
  const [ticketCount, setTicketCount] = useState(1);
  const [totalCost, setTotalCost] = useState(0);
  const [showPopup, setShowPopup] = useState(false);

  const handlePopupOpen = () => {
    setShowPopup(true);
  };

  const handlePopupClose = () => {
    setShowPopup(false);
  };

  const handleTicketCountChange = (e) => {
    const count = e.target.value;
    setTicketCount(count);
    setTotalCost(count * Number(lotteryData.ticketPriceUnformated));
  };

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Card sx={{ minWidth: "350px", width:"100%" }}>
          <CardContent>
            <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
              <TicketIcon sx={{ marginRight: 2, backgroundColor: "primary.main", borderRadius: "50%", padding: "10px", width: "45px", height: "45px" }} />
              Tickets
            </Typography>
            <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
              Total: {lotteryData && lotteryData.numberOfTickets}
            </Typography>
            <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
              Sold: {lotteryData && lotteryData.currentNumberOfTickets}
            </Typography>
            <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
              Price: {lotteryData && lotteryData.ticketPrice} MATIC
            </Typography>
          </CardContent>
          <CardActions sx={{ justifyContent: "end" }}>
            <Button
              color="primary"
              variant="contained"
              onClick={handlePopupOpen}
              sx={{ color: "#342134", cursor: "pointer", marginRight: 1.5 }}
            >
              Buy ticket
            </Button>
          </CardActions>
        </Card>
      </Box>

      {showPopup && (
        <div className="popup">
          <div className="popup-content" id='ticket-content'>
            <div className="popup-header">
              <h2>Comprar Tickets</h2>
            </div>
            <div className="popup-body">
              <div className="input-box">
                <label>Cantidad de Tickets:</label>
                <input type="number" value={ticketCount} onChange={handleTicketCountChange} min="1" />
              </div>
              <p>Costo total: {web3.utils.fromWei(totalCost.toString(), 'ether')} MATIC</p>
            </div>
            <div className="popup-footer">
              <button onClick={handlePopupClose}>Cancelar</button>
              <button onClick={() => buyTicket(web3, lotteryContract, accounts, ticketCount, lotteryData, openPopup)} id='popup-buy-btn'>Confirmar Compra</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
