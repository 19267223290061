import handleTransactionError from '../utils/handleTransactionError';

const getLotteryHistory = async (lotteryContract, openPopup) => {
  try {
    const history = await lotteryContract.methods.getLotteryHistory().call();
    console.log('Lottery history:', history);
    return history;
  } catch (error) {
    console.error('Error fetching lottery history:', error);
    handleTransactionError(error, openPopup);
  }
};

export default getLotteryHistory;
