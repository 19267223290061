import React, { useState } from 'react';
import { Box, Card, CardActions, CardContent, Button, Typography } from '@mui/material';
import addOwner from '../contracts/addOwner';

export default function AddOwner({ accounts, lotteryContract, openPopup }) {
  const [newOwner, setNewOwner] = useState("");

  const handleNewOwnerAdd = (e) => {
    setNewOwner(e.target.value);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
      <Card sx={{ minWidth: "350px" }}>
        <CardContent>
          <Typography variant="h5" sx={{ display: "flex", alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#342134" }} color={"white"}>
            Cambiar Propietario
          </Typography>
          <Typography sx={{ display: "flex", paddingX: 2, paddingY: 1, alignItems: "center", mb: 1.5, borderRadius: "50px", backgroundColor: "#222" }} color={"white"}>
            <label htmlFor="new-owner">Nueva Dirección del Propietario:</label>
            <input type="text" id="new-owner" value={newOwner} onChange={handleNewOwnerAdd} />
          </Typography>
        </CardContent>
        <CardActions sx={{ justifyContent: "end" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => addOwner(lotteryContract, accounts, newOwner, openPopup)}
            sx={{ color: "#342134", cursor: "pointer", marginRight: 1.5 }}
          >
            Cambiar Propietario
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
}
