import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import getLotteryHistory from '../contracts/getLotteryHistory';

const LotteryHistory = ({ lotteryContract, openPopup }) => {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const fetchHistory = async () => {
      const lotteryHistory = await getLotteryHistory(lotteryContract, openPopup);
      setHistory(lotteryHistory);
    };

    if (lotteryContract) {
      fetchHistory();
    }
  }, [lotteryContract, openPopup]);

  return (
    <Box sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}>
      <Card sx={{ minWidth: "350px" }}>
        <CardContent>
          <Typography variant="h5" sx={{ mb: 2, borderRadius: "50px", backgroundColor: "#342134", color: "white", padding: 1 }}>
            Historial de Loterías
          </Typography>
          <List>
            {history.map((lottery, index) => (
              <ListItem key={index} divider>
                <ListItemText
                  primary={`Lotería ${index + 1}`}
                  secondary={`Boletos: ${lottery.numberOfTickets}, Vendidos: ${lottery.currentNumberOfTickets}, Precio del Boleto: ${lottery.ticketPrice} Wei, Finalizado: ${lottery.ended}`}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Box>
  );
};

export default LotteryHistory;
