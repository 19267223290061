const handleTransactionError = (error, openPopup) => {
  if (typeof openPopup !== 'function') {
    console.error('openPopup is not a function');
    return;
  }

  if (error.code === -32603) {
    openPopup('Error Interno', 'Error interno del RPC. Por favor, revisa la consola para más detalles.');
  } else if (error.code === -32000) {
    openPopup('Error de Fondos', 'No tienes suficientes fondos para realizar esta acción. Por favor, asegúrate de tener suficiente MATIC en tu cuenta.');
  } else if (error.code === 4001) {
    openPopup('Transacción Rechazada', 'Has rechazado la transacción. Por favor, aprueba la transacción para continuar.');
  } else {
    openPopup('Error en la Transacción', 'Hubo un problema con la transacción. Por favor, intenta nuevamente.');
  }
};

export default handleTransactionError;
